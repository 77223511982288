<template>
	<div class="form-field-control mod-has_icon address_field"
		:class="{
			'show_results': show_results && results.length > 0,
			'is-invalid': isInvalid && !is_loading
		}"
		 v-closable="{
			exclude: [],
			handler: blur
		}">
		<slot />

		<Icon v-if="!isInvalid || is_loading" name="location" />
		<Icon v-else class="mod-warning"
		      name="exclamation-triangle"
		      title="In order for us to calculate an instant quoatation, you will need to select an address from the dropdown list that appears as you type." />

		<input
			type="text"
			v-model="query"
			required
			:placeholder="placeholder ?? 'Type city, town, or postcode'"
			@input="search()"
			@focus="show_results = true"
		>

		<div class="address_field-results"
			v-if="show_results && results.length > 0">
			<div class="address_field-result"
				v-for="result in results"
				@click="selectResult(result)">
				{{ result.description }}
			</div>
		</div>

		<Teleport to="body">
			<div class="address_field-popup"
			 :class="{'is-open': requires_confirm}">
				<div class="address_field-popup-wrap">
					<GMapMap
						ref="map"
						:center="position"
						:zoom="17"
						style="height: 400px;"
					>
						<GMapMarker
							:position="position"
							:draggable="true"
							@dragend="pointChanged"
						>
							<GMapInfoWindow>
								<strong>{{ query }}</strong>
							</GMapInfoWindow>
						</GMapMarker>
					</GMapMap>

					<div class="address_field-popup-buttons">
						<button type="button"
								class="button mod-outline"
								@click="cancel">
							Cancel
						</button>
						<button type="button"
								class="button mod-secondary"
								@click="confirm">
							Confirm
						</button>
					</div>
				</div>
			</div>
		</Teleport>
	</div>
</template>

<script>
import Icon from "./Icon.vue";

export default {
	name: "AddressField",

	components: {
		Icon,
	},

	props: [
		'modelValue',
		'placeholder'
	],

	emits: [
		'update:modelValue',
	],

	data() {
		let center = {lat: 52.9219443, lng: -1.4781761169};
		return {
			query: this.modelValue.address ?? '',
			center: center,
			position: center,
			requires_confirm: false,
			show_results: false,
			results: [],
			is_loading: false,
		}
	},

	mounted() {
		this.$refs.map.$mapPromise.then((map) => {
			this.geocoder = new google.maps.Geocoder;
			this.places = new google.maps.places.PlacesService(map);
			this.autocomplete = new google.maps.places.AutocompleteService();
			this.sessionToken = new google.maps.places.AutocompleteSessionToken();
		});
	},

	computed: {
		isInvalid: function() {
			return this.modelValue.address && !this.isGeoLocated();
		},
	},

	methods: {

		isGeoLocated: function() {
			return this.modelValue.longitude && this.modelValue.latitude;
		},


		search() {
			if (this.query.length < 5) return;

			// Debounce so not searching on every key stroke
			const timeoutId = window.setTimeout(() => {}, 0);
			for (let id = timeoutId; id >= 0; id -= 1) {
				window.clearTimeout(id);
			}

			setTimeout(() => {
				this.getResults();
			}, 500);
		},


		getResults() {
			let request = {
				input: this.query,
				componentRestrictions: 	{ country: 'uk' },
				sessionToken: this.sessionToken,
			};
			let _self = this;

			_self.is_loading = true;

			this.autocomplete.getPlacePredictions(request, function(results) {
				if (results) {
					_self.results = results.filter(result => {
                        // Remove broad options
                        return !result.types.includes('locality')
                            && !result.types.includes('administrative_area_level_1')
                            && !result.types.includes('administrative_area_level_2');
					});
				} else {
					_self.results = [];
				}

				_self.is_loading = false;
				_self.show_results = true;
				_self.scrollToView();
			});
		},

		scrollToView() {
			if (window.visualViewport.height > 800) return;

			this.$el.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			})
		},

		blur() {
			// Close the autocomplete results
			this.show_results = false;

			// If a value wasn't set from the map, then the location could not
			// be confirmed. Use the query as the address
			if (!this.modelValue.address) {
				this.modelValue.address = this.query;
				this.$emit('update:modelValue', this.modelValue);
			}
		},


		/**
		 * New address selected from Autocomplete
		 */
		selectResult(place) {
			let _self = this;

			_self.results = [];
			_self.show_results = false;
			_self.requires_confirm = true;
			_self.is_loading = true;

			let request = {
				placeId: place.place_id,
				sessionToken: _self.sessionToken,
				fields: [
					'name',
					'formatted_address',
					'address_components',
					'geometry',
					'types',
				]
			}

			_self.places.getDetails(request, function(place, status) {
				_self.setPlace(place);
				_self.is_loading = false;
			});
		},

		confirm() {
			this.requires_confirm = false;
		},

		cancel() {
			this.requires_confirm = false;
			this.setPlace(null);
		},


		/**
		 * Handle the map pin being dragged.
		 * Lookup the Place from the LatLng Pair
		 */
		pointChanged(event) {
			let _self = this;

			_self.is_loading = true;

			_self.geocoder.geocode({'location': event.latLng}, (results, status) => {
				if (status !== 'OK' || !results[0]) {
					_self.setPlace(null);
					_self.is_loading = false;
					return;
				}

				_self.places.getDetails({placeId: results[0].place_id}, (place, status) => {
					if (status === 'OK') {
						_self.setPlace(place);
					} else {
						_self.setPlace(null);
					}
					_self.is_loading = false;
				});
			});
		},



		/**
		 * Nicely format the address and set the model values
		 */
		setPlace(place) {
			let address = '';
			let lat = null;
			let lng = null;

			if (place) {
				if (place.formatted_address) {
					address = this.getNiceAddress(place);
				} else if (place.name) {
					address = place.name;
				}

				if (place.geometry) {
					lat = place.geometry.location.lat();
					lng = place.geometry.location.lng();
				}
			}


			if (lat === null) {
				this.position = this.center;
			} else {
				this.position = {lng: lng, lat: lat};
			}

			this.query = address;
			this.modelValue.address = address;
			this.modelValue.longitude = lng;
			this.modelValue.latitude = lat;

			this.$emit('update:modelValue', this.modelValue);
		},


		getNiceAddress(place) {
			if (!place || !place.hasOwnProperty('formatted_address')) {
				return '';
			}

			let formatted_address = place.formatted_address;

			// Establishments do not include the actual place name in the formatted address
			if (place.name && formatted_address.indexOf(place.name) < 0) {
				formatted_address = place.name + ', ' + formatted_address;
			}

			if (formatted_address.endsWith(', UK')) {
				formatted_address = formatted_address.slice(0, -4);
			}

			return formatted_address;
		}
	},
}
</script>
