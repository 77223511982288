<script>
import { createApp, ref } from 'vue/dist/vue.esm-bundler'

import ArticleThumbs from './ArticleThumbs.vue'
import Gallery from './Gallery.vue'

import EnquiryJourney from './EnquiryJourney.vue';
import EnquiryJobInfo from './EnquiryJobInfo.vue';
import EnquiryLuggage from "./EnquiryLuggage.vue";
import EnquiryJourneyExtras from "./EnquiryJourneyExtras.vue";
import EnquiryContact from "./EnquiryContact.vue";
import EnquirySummary from "./EnquirySummary.vue";
import EnquiryAccount from "./EnquiryAccount.vue";
import QuoteLoading from "./QuoteLoading.vue";
import RequoteButton from "./RequoteButton.vue";

import Payment from "./Payment.vue";
import PaymentMethod from "./PaymentMethod.vue";
import GpayTest from "./GpayTest.vue";

import VideoFitter from "./VideoFitter.vue";
import Flash from './Flash.vue';
import http from '../plugins/http';
import Closable from '../directives/closable';

import VueGoogleMaps from '@fawmi/vue-google-maps'
import { VueAgile } from 'vue-agile'
import { setupCalendar } from 'v-calendar';
import PrimeVue from "primevue/config";
import * as Sentry from "@sentry/vue";


const app = createApp(
	{
		components: {
			agile: VueAgile,
			ArticleThumbs,
			Gallery,
			EnquiryJourney,
			EnquiryJobInfo,
			EnquiryLuggage,
			EnquiryJourneyExtras,
			EnquiryContact,
			EnquiryAccount,
			EnquirySummary,
			QuoteLoading,
			RequoteButton,
			Payment,
			PaymentMethod,
			GpayTest,
			Flash,
			VideoFitter,
		},
		data() {
			return {
				is_loading: false,
			}
		},
		props: [
			'user_id',
		],
		provide() {
			return {
				is_loading: ref(false),
				errors: ref({}),
				error: ref(null),
				warning: ref(null),
				success: ref(null),
				info: ref(null),
				user_id: this.user_id,
			}
		},
		mounted() {
		}
	},

	// Inject data attrs as props. Ugh.
	// https://stackoverflow.com/questions/64010560/passing-props-to-vue-root-instance-via-attributes-on-element-the-app-is-mounted
	{ ...document.querySelector("#app").dataset }
);

app.config.errorHandler = (err) => {
    console.log(err);
	Sentry.captureException(err);
}

app.use(setupCalendar, {});

app.use(PrimeVue, {
	unstyled: true
});

app.use(VueGoogleMaps, {
    load: {
        key: GMAPS_API_KEY,
		libraries: 'places',
    },
});

app.directive('closable', Closable);


app.config.globalProperties.$filters = {
	currency(value) {
		value = parseFloat(value);

		let formatter;

		if (value % 1 === 0) {
			formatter = new Intl.NumberFormat('en-GB', {
				style: 'currency',
				currency: 'GBP',
				minimumFractionDigits: 0,
			});
		} else {
			formatter = new Intl.NumberFormat('en-GB', {
				style: 'currency',
				currency: 'GBP',
				maximumFractionDigits: 2,
			});
		}

		return formatter.format(value);
	},
};


app.use(http);

export default app;
</script>
