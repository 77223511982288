<template>
	<div ref="viewport" class="viewport">
		<slot></slot>
	</div>
</template>

<script>
import 'v-calendar/dist/style.css';
import Icon from "./Icon.vue";

export default {
	name: "VideoFitter",

	mounted() {
		this.fitVideo();
		window.addEventListener('resize', this.fitVideo);
	},

	beforeDestroy() {
		window.removeEventListener('resize', this.fitVideo);
	},

	methods: {
		fitVideo() {
			const viewport = this.$refs.viewport;
			if (!viewport) return;
			const video = viewport.firstElementChild;
			if (!video) return;

			const container = viewport.parentElement;
			const minWidth = 300;
			const vidWOrig = video.videoWidth || video.getAttribute('width') || 640;
			const vidHOrig = video.videoHeight || video.getAttribute('height') || 360;

			viewport.style.width = `${container.clientWidth}px`;

			const scaleH = container.clientWidth / vidWOrig;
			const scaleV = container.clientHeight / vidHOrig;
			let scale = Math.max(scaleH, scaleV);

			if (scale * vidWOrig < minWidth) {
				scale = minWidth / vidWOrig;
			}

			viewport.style.width = `${container.clientWidth * 5}px`;
			viewport.style.marginLeft = `-${container.clientWidth * 2}px`;

			video.style.width = `${scale * vidWOrig}px`;
			video.style.height = `${scale * vidHOrig}px`;

			viewport.scrollLeft = (video.clientWidth - container.clientWidth) / 2;
			viewport.scrollTop = (video.clientHeight - container.clientHeight) / 2;
		}
	}
};
</script>

<style>
.viewport {
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	position: relative;
	width: 100%;
	height: 100%;
}
</style>
